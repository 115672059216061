<template>
  <LineChart
    :chart-data="config"
    :chart-options="options"
    css-classes="dashboard-line-chart"
  />
</template>

<script>
import { Line } from "vue-chartjs/legacy";
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { mapState } from "vuex";

ChartJS.register(
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

export default {
  name: "DashboardLineChart",
  components: {
    LineChart: Line,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "number",
      validator: (val) => ["number", "money", "percent"].includes(val),
    },
  },
  data() {
    return {
      options: {
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            padding: { x: 11, y: 8 },
            bodyFont: {
              size: 14,
              weight: 400,
            },
            callbacks: {
              title: () => {},
              label: (ctx) =>
                `${
                  this.type === "money"
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                      }).format(ctx.parsed.y)
                    : this.type === "percent"
                    ? `${ctx.parsed.y}%`
                    : ctx.parsed.y.toString()
                } (${ctx.label})`,
            },
          },
        },
        scales: {
          y: {
            suggestedMin: 0,
            ticks: {
              callback: (value) =>
                this.type === "money"
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(value)
                  : this.type === "percent"
                  ? `${value}%`
                  : value % 1 === 0
                  ? value
                  : "",
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: (value, index) => {
                const skipEvery =
                  this.labels.length <= 7
                    ? 1
                    : this.labels.length <= 24
                    ? 2
                    : this.labels.length <= 31
                    ? 3
                    : 30;
                return index % skipEvery === 0 ? this.labels[value] : "";
              },
              maxRotation: 0,
              minRotation: 0,
              autoSkip: false,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      country: (state) => state.venues.selectedVenue?.country,
    }),
    config() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            fill: true,
            borderColor: "#286EED",
            tension: 0.4,
            backgroundColor: "rgba(40, 110, 237, 0.08)",
            borderWidth: 2,
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.dashboard-line-chart {
  flex: 1;
  height: 300px;
}
</style>
