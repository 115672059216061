<template>
  <div class="dashboard-details__list">
    <div
      v-for="item in items"
      :key="item.id"
      class="dashboard-details__list__item"
      :class="{ clickable: isClickable }"
      @click="isClickable && $emit('item-click', item)"
    >
      <div class="dashboard-details__list__item__left">
        <span class="dashboard-details__list__item__name">
          {{ item.name }}
        </span>
        <span
          v-if="item.amount > 0"
          class="dashboard-details__list__item__amount m-success"
        >
          + {{ formatAmount(item.amount) }}
        </span>
        <span
          v-else-if="item.amount < 0"
          class="dashboard-details__list__item__amount m-danger"
        >
          - {{ formatAmount(item.amount) }}
        </span>
        <span
          v-if="item.extraInfo"
          class="dashboard-details__list__item__extra"
        >
          {{ item.extraInfo }}
        </span>
      </div>
      <div class="dashboard-details__list__item__right">
        <Icon name="calendar" color="primary" />
        {{ item.date }}
      </div>
    </div>
    <infinite-loading
      :identifier="infiniteId"
      @infinite="$emit('infinite', $event)"
    >
      <Loader slot="spinner" class="mx-auto" color="primary" size="m" />
      <div class="empty" slot="no-results">No data available</div>
      <div slot="no-more"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardDetailsList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    infiniteId: {
      type: Number,
      default: +new Date(),
    },
  },
  computed: {
    ...mapState({
      country: (state) => state.venues.selectedVenue?.country,
    }),
  },
  methods: {
    formatAmount(amount) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      }).format(Math.abs(amount) / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-details {
  &__title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $secondary-500;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0 15px;
      border-bottom: 1px solid $secondary-400;
      transition: 0.3s;

      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: $secondary-100;
        }
      }

      &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $secondary-500;
      }

      &__amount {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &.m-success {
          color: $success;
        }

        &.m-danger {
          color: $danger;
        }
      }

      &__extra {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $secondary-400;
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        line-height: 24px;
        color: $primary;
      }
    }
  }
}
</style>
