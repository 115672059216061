<template>
  <div class="dashboard-statistics-details-page">
    <BackTitle class="mb-40" @click="goBack">
      {{ title }}
      <template v-slot:append>
        <div class="dashboard-statistics-details-page__date-toggle">
          Filter by event date
          <Switcher
            v-model="isDateFieldBookingAt"
            label="Filter by booking date"
          />
        </div>
      </template>
    </BackTitle>
    <div class="grid-2 mb-40">
      <Select
        v-model="activeProductFilterId"
        :options="productOptions"
        label="Product"
      />
      <div class="grid-2__full-item divider" />
      <div
        class="grid-2__full-item dashboard-statistics-details-page__flex-space-between"
      >
        <span class="dashboard-statistics-details-page__dates">
          {{ dateRangeFilterString }}
        </span>
        <SelectButtons v-model="activePeriodFilter" :options="rangeOptions" />
      </div>
    </div>
    <Loader
      v-if="isChartLoading"
      class="mx-auto mb-48"
      size="m"
      color="primary"
    />
    <div v-else class="dashboard-statistics-details-page__chart-wrapper mb-48">
      <DashboardLineChart
        v-if="chartData && chartData.length"
        :data="chartData"
        :labels="chartLabels"
        :type="chartType"
      />
      <div v-else class="empty">No data available</div>
    </div>
    <p class="dashboard-statistics-details-page__subtitle mb-40">Bookings</p>
    <DashboardDetailsList
      :items="formattedList"
      is-clickable
      :infinite-id="infiniteId"
      @infinite="infiniteHandler"
      @item-click="goToBookingDetails"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import {
  DASHBOARD_STATS_LABELS_MAP,
  DashboardStatsConfig,
} from "@/helpers/const";
import DashboardDetailsList from "@/components/dashboard/DashboardDetailsList";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment";
import {
  getDateRangeByType,
  getFormattedSlots,
  getVenueTimeFormat,
} from "@/helpers/utils";
import SelectButtons from "@/components/common/SelectButtons";
import { dateRangeOptions } from "@/helpers/mocks";
import DashboardLineChart from "@/components/dashboard/DashboardLineChart";
export default {
  name: "DashboardStatisticsDetailsPage",
  components: {
    DashboardLineChart,
    SelectButtons,
    DashboardDetailsList,
    BackTitle,
  },
  data() {
    return {
      infiniteId: +new Date(),
      isChartLoading: false,
      limit: 12,
    };
  },
  computed: {
    ...mapState({
      allStatistics: (state) => state.dashboard.statistics,
      detailsList: (state) => state.dashboard.detailsList,
      detailsChart: (state) => state.dashboard.detailsChart,
      products: (state) => state.products.list,
      productFilterId: (state) => state.dashboard.productFilterId,
      periodFilter: (state) => state.dashboard.periodFilter,
      dateRangeFilter: (state) => state.dashboard.dateRangeFilter,
      dateField: (state) => state.dashboard.dateField,
      venue: (state) => state.venues.selectedVenue,
    }),
    rangeOptions() {
      return [...dateRangeOptions].filter((option) => {
        return option.value in this.allStatistics;
      });
    },
    title() {
      return DASHBOARD_STATS_LABELS_MAP[this.$route.params.statKey];
    },
    formattedList() {
      return this.detailsList.map((item) => ({
        ...item,
        date: this.formatBookingDate(item),
        extraInfo: item.withoutPaymentOptions?.length
          ? `Paid with ${item.withoutPaymentOptions.join(", ")}`
          : null,
      }));
    },
    chartType() {
      return DashboardStatsConfig[this.$route.params.statKey]?.componentProps
        ?.type;
    },
    chartData() {
      return this.detailsChart
        ? Object.values(this.detailsChart.data).map((amount) =>
            Math.abs(this.chartType === "money" ? amount / 100 : +amount)
          )
        : undefined;
    },
    chartLabels() {
      if (this.detailsChart) {
        if (this.detailsChart.type === "hourly") {
          const timeFormat = getVenueTimeFormat();
          return Object.keys(this.detailsChart.data).map((hour) =>
            moment(this.dateRangeFilter.from)
              .startOf("date")
              .hour(+hour)
              .format(timeFormat)
          );
        } else {
          return Object.keys(this.detailsChart.data).map((date) =>
            moment(date).format("MM.DD.YYYY")
          );
        }
      }
      return null;
    },
    productOptions() {
      return [
        {
          name: "All",
          value: null,
        },
        ...this.products.map((item) => ({
          name: item.name,
          value: item.id,
        })),
      ];
    },
    activeProductFilterId: {
      get() {
        return this.productFilterId;
      },
      set(newValue) {
        if (this.activeProductFilterId !== newValue) {
          this.SET_PRODUCT_FILTER_ID(newValue);
          this.resetStatisticsCache();
          this.resetDetailsList();
          this.refetchDetailsChart();
        }
      },
    },
    activePeriodFilter: {
      get() {
        return this.periodFilter;
      },
      set(newValue) {
        if (this.activePeriodFilter !== newValue) {
          this.SET_PERIOD_FILTER(newValue);
          let dateRange = getDateRangeByType(newValue, "YYYY-MM-DD");
          if (dateRange) {
            this.SET_DATE_RANGE_FILTER(dateRange);
            this.resetDetailsList();
            this.refetchDetailsChart();
          }
        }
      },
    },
    dateRangeFilterString() {
      const [from, to] = this.dateRangeFilter;
      const formattedFrom = moment(from).format("MM.DD.YYYY");
      if (from === to) {
        return formattedFrom;
      } else {
        const formattedTo = moment(to).format("MM.DD.YYYY");
        return `${formattedFrom} - ${formattedTo}`;
      }
    },
    isDateFieldBookingAt: {
      get() {
        return this.dateField === "bookingAt";
      },
      set(isBookingAt) {
        if (this.isDateFieldBookingAt !== isBookingAt) {
          this.resetStatisticsCache();
          this.SET_DATE_FIELD(isBookingAt ? "bookingAt" : "createdAt");
          this.resetDetailsList();
          this.refetchDetailsChart();
        }
      },
    },
  },
  created() {
    this.resetDetailsList();
    this.refetchDetailsChart();
  },
  methods: {
    ...mapActions({
      fetchDetailsList: "dashboard/fetchDetailsList",
      resetStatisticsCache: "dashboard/resetStatisticsCache",
      fetchDetailsChart: "dashboard/fetchDetailsChart",
    }),
    ...mapMutations({
      SET_PRODUCT_FILTER_ID: "dashboard/SET_PRODUCT_FILTER_ID",
      SET_DATE_RANGE_FILTER: "dashboard/SET_DATE_RANGE_FILTER",
      SET_PERIOD_FILTER: "dashboard/SET_PERIOD_FILTER",
      SET_DATE_FIELD: "dashboard/SET_DATE_FIELD",
      SET_DETAILS_LIST: "dashboard/SET_DETAILS_LIST",
    }),
    resetDetailsList() {
      this.SET_DETAILS_LIST([]);
      this.infiniteId += 1;
    },
    async refetchDetailsChart() {
      try {
        this.isChartLoading = true;
        await this.fetchDetailsChart(this.$route.params.statKey);
      } finally {
        this.isChartLoading = false;
      }
    },
    async infiniteHandler($state) {
      try {
        const length = await this.fetchDetailsList({
          statKey: this.$route.params.statKey,
          limit: this.limit,
        });
        if (length) {
          $state.loaded();
          if (length < this.limit) {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (e) {
        $state.complete();
      }
    },
    goBack() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    formatBookingDate(item) {
      const timezone = this.venue.timezone;
      return `${moment
        .utc(item.dateIso)
        .format("ddd MMM D, YYYY")} ${getFormattedSlots(item, timezone)}`;
    },
    goToBookingDetails(item) {
      this.$router.push({
        name: "BookingDetail",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-statistics-details-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__date-toggle {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    font-weight: 400;
  }

  &__dates {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $secondary-500;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;
  }

  &__chart-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>
